
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121213;
    --border-color: #d3d6da;
    --key-bg: #818384;
    --color-present: #b59f3b;
    --color-correct: #538d4e;
    --color-absent: #3a3a3c;
    --tile-text-color: #ffffff;
    --link-color: yellow;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --background-color: #ffffff;
    --border-color: #d3d6da;
    --key-bg: #818384;
    --color-present: #c9b458;
    --color-correct: #6aaa64;
    --color-absent: #787c7e;
    --tile-text-color: #000000;
    --link-color: blue
  }
}

a {
  color: var(--link-color)
}

body {
  font-size: 20px;
  background-color: var(--background-color);
  color: var(--tile-text-color);
  min-height: 100vh;
}
.App {
  font-family: sans-serif;
  text-align: center;
}

.header {
  text-align: center;
}
.header * {
  margin: 1rem;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.box {
  background: var(--key-bg);
  padding: 0.5rem;
  margin: 0.2rem;
  border: 1px solid var(--border-color);
  min-height: 2rem;
  min-width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: initial;
}

.none,
.key.none,
.box.none {
  background-color: var(--color-absent);
  border: 1px solid var(--color-absent);
}
.bull,
.key.bull,
.box.bull {
  background-color: var(--color-correct);
  border: 1px solid var(--color-correct);
}
.cow,
.key.cow,
.box.cow {
  background-color: var(--color-present);
  border: 1px solid var(--color-present);
}

.boxline {
  display: flex;
}

.boxgrid {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.key {
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  cursor: pointer;
  margin: 0.2rem;
  background-color: var(--key-bg);
}
.keys {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 20rem;
}
.controls {
  margin: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.keyboard {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: var(--background-color);
  color: var(--tile-text-color);
  margin: 2rem auto;
  padding: 20px;
  border: 1px solid var(--border-color);
  width: 80%;
  text-align: center;
}
.modal button {
  font-size: 1rem;
  padding: 1rem;
  background-color: var(--color-correct);
  border: 1px solid var(--color-correct);
  color: var(--tile-text-color);
  margin: 1rem;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

footer {
  bottom: 0;
  font-size: 0.75rem;
}